import { Box, Card, Container, Link, Text } from "@radix-ui/themes";
import { Link as RemixLink } from "@remix-run/react";
import "./AuthPageContainer.scss";
import StickyContainer from "./ui/StickyContainer";

/**
 * Container for styling auth pages
 */
export default function AuthPageContainer(props: React.PropsWithChildren) {
  return (
    <StickyContainer
      className="AuthPageContainer"
      footer={
        <Box p="4">
          <Text size="2">
            <Link asChild>
              <RemixLink to="https://infilla.com">Terms of Service</RemixLink>
            </Link>{" "}
            <Text color="gray">and</Text>{" "}
            <Link asChild>
              <RemixLink to="https://infilla.com">Privacy Policy</RemixLink>
            </Link>
          </Text>
        </Box>
      }
    >
      <Container size="1" my="8">
        <Card>{props.children}</Card>
      </Container>
    </StickyContainer>
  );
}
