import { Outlet } from "@remix-run/react";
import AuthPageContainer from "../components/AuthPageContainer";
import { appFaviconLink } from "../utils/page-meta";
import { LinksFunction } from "@remix-run/node";

export const links: LinksFunction = () => {
  return [appFaviconLink()];
};

export default function AuthLayout() {
  return (
    <AuthPageContainer>
      <Outlet />
    </AuthPageContainer>
  );
}
